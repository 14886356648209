import styled from "styled-components";

const LogoImage = styled.img`
  width: 100%;
`

const Logo = () => {
  return (
    <LogoImage src="/images/faroeste_logo_white.svg" alt="Faroeste" />
  )
}

export default Logo;
