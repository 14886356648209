import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, #__next {
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
    color: #F8EADA;
  }

  .bg-white {
  background: #F8EADA
  }

  .white {
    color: #F8EADA;
  }

  * {
    font-weight: normal;
  }

  @font-face {
      font-family: 'Lino';
      src: url("/fonts/RDRLino-Regular.ttf");
  }

  @font-face {
      font-family: 'Hapna';
      src: url("/fonts/HapnaSlabSerif-DemiBold.ttf");
  }

  @font-face {
      font-family: 'Catalog-Bold';
      src: url("/fonts/RDRCatalogueBold-Bold.ttf");
  }

  @font-face {
      font-family: 'Gothica';
      src: url("/fonts/RDRGothica-Regular.ttf");
  }

  @font-face {
      font-family: 'Redemption';
      src: url("/fonts/Redemption.ttf");
  }

`;

export default GlobalStyle;
