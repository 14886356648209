import React from "react";
import styled from "styled-components";
import Link from "next/link";
import Button from "@material-ui/core/Button";

const Container = styled.div`
  width: 100%;

  align-items: center;
  justify-content: center;

  display: flex;

  @media (max-width: 800px) {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  font-size: 14px;
  font-family: "Lino";
`;

const StyledFeaturedButton = styled(Button)`
  margin-left: 10px;
  font-size: 14px;
  font-family: "Lino";
`;


const SiteMenu = () => {
  return (
    <Container>
      <Link href="/allowlist" passHref>
        <StyledButton>Allowlist</StyledButton>
      </Link>
     <Link href="https://faroeste.tebex.io/?currency=BRL" passHref> 
        <StyledButton>Loja</StyledButton>
      </Link>
      <Link href="https://infos.faroesterp.com/" passHref>
        <StyledButton>Regras</StyledButton>
      </Link> 
      <Link href="https://faroesterp.com/discord" passHref>
        <StyledButton>Discord</StyledButton>
      </Link>
    </Container>
  );
};

export default SiteMenu;
