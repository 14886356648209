import React from "react";
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Button, { ButtonProps } from "@material-ui/core/Button";
import styled from "styled-components";
import { DiscordIcon } from "components/discordicon";

const StyledButton = styled(Button)`
  margin-left: 10px;
  font-size: 14px;
  font-family: "Lino";
  background:rgba(200, 200, 200, 0.6);
  padding-inline: 20px;
  padding-block: 5px;

  display:flex;

  &:hover{
    background: #5865F2;
  }
`;

const {
  publicRuntimeConfig: { discordClientId },
} = getConfig();

const Login = (props: ButtonProps & { text?: string }) => {
  const baseUri = window.location.protocol + '//' + window.location.host;
  const redirectUri = `${baseUri}/api/discord/authorize`;
  const scopes = ['connections', 'guilds', 'identify', 'email'];
  const loginUri = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(' ')}`;

  return (
    <StyledButton href={loginUri} {...props}>
      <DiscordIcon size={"20px"} color={"#F8EADA"}/> 
      <span style={{ marginLeft: "10px"}}> {props.text || `Entrar`} </span>
    </StyledButton>
  )
}

export default dynamic(() => Promise.resolve(Login), {
  ssr: false
})
