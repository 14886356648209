import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#4977DB",
    },
    secondary: {
      main: "#799AD8",
    },
    background: {
      paper: "#111",
      default: "#000",
    },
  },
  typography: {
    fontFamily: "Hapna, serif",
    fontSize: 16,
  },
  overrides: {
    MuiButton : {
      root : {
        color: "#F8EADA"
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#000000",
        fontSize: "16px",
      },
      arrow: {
        "&::before": {
          backgroundColor: "#000000",
        },
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 1,
      },
    },
    MuiTableCell: {
      head: {
        borderColor: "#2D2D2C",
      },
      root: {
        borderBottom: "1px solid #2D2D2C",
      },
    },
  },
});

export default theme;
